import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService } from 'src/app/shared/api.service';
import { Globals } from 'src/app/shared/globals';

@Component({
  selector: 'app-single-application',
  templateUrl: './single-application.component.html',
  styleUrls: ['./single-application.component.css']
})
export class SingleApplicationComponent implements OnInit {
  response;
  id;
  opportunityId;
  objectKeys = Object.keys;
  constructor(private router: Router, private route: ActivatedRoute, private http: ApiService, private globals: Globals, public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['response'];
      this.opportunityId = params['id'];

      this.getResponse();
    })
  }

  getResponse() {
    this.http.get('/responses/'+this.id, 0)
      .subscribe((data: any) => {
        this.response = data;
      });
  }

  goTo(responseId) {
    this.router.navigate(['./'+responseId], { relativeTo: this.route })
  }

  goBack(){
    this.router.navigate(['./settings/applications'])
  }
}
