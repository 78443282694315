import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { Globals } from 'src/app/shared/globals';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-apply-opportunity',
  templateUrl: './apply.component.html',
  styles: []
})
export class ApplyOpportunityComponent implements OnInit {

  api_route = 'responses';
  id: number;
  private sub: any;
  opportunity: {} = {};
  response = {
    opportunityId: '',
    body: '',
    customFields: {},
  };
  applicationSent: boolean;
  files = '';
  errors = [];
  fieldUploaded: String = '';
  user = {};
  constructor(private router: Router, private route: ActivatedRoute, private http: ApiService, private globals: Globals) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.getOpportunity();
    })

    this.getUser();
  }

  getOpportunity() {
    this.http.get('/opportunities/' + this.id, 0)
      .subscribe((data) => {
        console.log(data);
        if (data['customFields']) {
          for (let i = 0; i < data['customFields'].length; i++) {
            const field = data['customFields'][i];
            this.response.customFields[field.fieldName] = {
              url: '',
              value: ''
            }
          }
        }

        this.opportunity = data;


        this.response.opportunityId = this.opportunity['_id'];
      });
  }

  getUser() {
    this.http.get('/users/current', {})
      .subscribe((data) => {
        console.log(data)
        this.user = data;
      });
  }

  save() {
    if (this.checkMandatory()) {
      this.http.post('/' + this.api_route, this.response, 0)
        .subscribe((data) => {
          console.log(this.response);
          console.log(data);
          this.applicationSent = true;
          window.scrollTo(0, 0)
        });
    }
  }

  fileUploaded(name) {
    this.fileUploaded = name;
    console.log(this.fileUploaded);
  }
  uploadFile(files: FileList) {
    let file = files.item(0);

    this.http.post('/' + this.api_route + '/' + this.id + '/upload/image', { filename: file.name, type: file.type }, {})
      .subscribe((data) => {
        let formData = new FormData();
        formData.append('key', data['credentials']['fields']['key']);
        formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
        formData.append('acl', data['credentials']['fields']['acl']);
        formData.append('policy', data['credentials']['fields']['policy']);
        formData.append('signature', data['credentials']['fields']['signature']);
        formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
        formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
        formData.append('file', file);

        this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
          if (response.type === HttpEventType.UploadProgress) {
            this.globals.progressBars[this.id] = Math.round(100 * response.loaded / response.total);
          } else if (response.type === HttpEventType.Response) {

            this.globals.progressBars[this.id] = 0;



            //Save image to model
            if (!this.response['files']) {
              this.response['files'] = [];
            }

            this.response['files'].push({
              name: file.name,
              url: JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'))
            });

            if (this.files == '') {
              this.files += file.name;
            } else {
              this.files += ', ' + file.name;
            }
          }
        });
      });
  }

  uploadCustomFile(files: FileList) {
    let file = files.item(0);

    this.http.post('/' + this.api_route + '/' + this.id + '/upload/image', { filename: file.name, type: file.type }, {})
      .subscribe((data) => {
        let formData = new FormData();
        formData.append('key', data['credentials']['fields']['key']);
        formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
        formData.append('acl', data['credentials']['fields']['acl']);
        formData.append('policy', data['credentials']['fields']['policy']);
        formData.append('signature', data['credentials']['fields']['signature']);
        formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
        formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
        formData.append('file', file);

        this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
          if (response.type === HttpEventType.UploadProgress) {
            this.globals.progressBars[this.id] = Math.round(100 * response.loaded / response.total);
          } else if (response.type === HttpEventType.Response) {

            this.globals.progressBars[this.id] = 0;



            //Save image to model
            let fieldToAdd = this.fileUploaded.toString()
            this.response['customFields'][fieldToAdd]['value'] = file.name;
            this.response['customFields'][fieldToAdd]['url'] = JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'));


          }
        });
      });
  }

  checkMandatory() {
    this.errors = [];

    for (let i = 0; i < this.opportunity['customFields'].length; i++) {
      const field = this.opportunity['customFields'][i];
      if (field.mandatory) {
        if (!this.response.customFields[field.fieldName]['value']) {
          this.errors.push(field.fieldName + ' is required')
        }
      }

    }

    return this.errors.length == 0;
  }
}
