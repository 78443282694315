import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as $ from 'jquery';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Globals } from './shared/globals';
import { ApiService } from './shared/api.service';
import { FacebookService } from './shared/facebook.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Association of Independent Music';
  token;

  contact = {
    name: '',
    message: ''
  };
  newsletter = {
    email: '',
    company: '',
    firstname: '',
    lastname: ''
  };
  contactSent: boolean;
  subscribed: boolean;
  emptyMessageError: boolean = false;

  @ViewChild('recaptcha') recaptchaElement: ElementRef;

  constructor(
    private router: Router,
    private fb: FacebookService,
    private http: ApiService,
    private route: ActivatedRoute,
    private globals: Globals,
    public ngxSmartModalService: NgxSmartModalService,
    private meta: Meta
  ) { }

  ngOnInit() {
    this.addRecaptchaScript();
    this.setMetadataTags();

    this.token = localStorage.getItem('token');
    if (this.token) {
      this.globals.loggedIn = true;
    }

    if (this.globals.loggedIn) {
      this.http.get('/users/current/type', 0).subscribe((data: any) => {
        this.globals.userType = data;
      });
      this.http.get('/users/current/company', 0).subscribe((data: any) => {
        console.log('Company');
        console.log(data);
        this.globals.memberType = data.memberType;
      });
    }

    this.http.get('/users/current/type', 0).subscribe((data: any) => {
      this.globals.userType = data;

      this.http.get('/users/current/company', 0).subscribe((data: any) => {
        this.globals.companyId = data._id;
        this.globals.memberType = data.memberType;
      });
    });

    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.fb.pageView();
        window.scrollTo(0, 0);
      }
    });

    //mobile menu
    $(document).on('click', '.js-hamburger', function () {
      $(this).toggleClass('is-active');
      $('.main-nav').toggleClass('menu-active');
      $('.search-form').removeClass('active');
    });

    $(document).on('click', '.search-btn', function () {
      $('header .search-form').toggleClass('active');
      $('.js-hamburger').removeClass('is-active');
      $('.main-nav').removeClass('menu-active');
    });

    // main menu
    $(document).on('mouseenter', '.main-nav__primary > li', function () {
      var elem = $(this);
      setTimeout(function () {
        if (elem.is(':hover')) {
          elem.addClass('active');
          $('.main-nav__primary > li')
            .not(elem)
            .removeClass('active');
        }
      }, 200);
    });

    $(document).on('mouseleave', '.main-nav__primary > li', function () {
      var elem = $(this);
      setTimeout(function () {
        if (!elem.is(':hover')) {
          elem.removeClass('active');
        }
      }, 200);
    });
  }

  setMetadataTags() {
    this.meta.addTags([
      { name: 'og:title', content: 'Association Of Independennt Music' },
      { name: 'og:description', content: 'AIM is the not-for-profit trade body exclusively representing the UK’s independent music sector, which makes up a quarter of the recorded music market. Now in its 20th year, AIM’s members range from the largest, most respected record labels and associated music businesses in the world to self-releasing artists and the next generation of entrepreneurs in music' },
      { name: 'og:image', content: 'assets/img/open-graph-banner.png' },
      { name: 'og:image:url', content: 'https://www.aim.org.uk/assets/img/open-graph-banner.png' },
      { name: 'og:image:type', content: 'image/png' },
      { name: 'twitter:title', content: 'Association Of Independennt Music' },
      { name: 'twitter:card', content: 'AIM is the not-for-profit trade body exclusively representing the UK’s independent music sector, which makes up a quarter of the recorded music market. Now in its 20th year, AIM’s members range from the largest, most respected record labels and associated music businesses in the world to self-releasing artists and the next generation of entrepreneurs in music' },
      { name: 'twitter:creator', content: '@aim_uk' },
    ]);
  }


  goTo(page) {
    this.router.navigate(['./' + page]);
  }

  signOut() {
    localStorage.clear();
    this.globals.loggedIn = false;
    this.router.navigate(['/']);
  }

  sendMessage() {
    this.emptyMessageError = false;
    if (!this.contact.message || this.contact.message == '') {
      this.emptyMessageError = true;
    } else {
      this.http.post('/contact/captcha', { token: this.globals.recaptchaToken }, {}).subscribe(data => {
        this.globals.recaptchaToken = '';
        if (data['response'] && data['response'] == true) {
          console.log('Sending Contact Message');
          this.http.post('/contact/10987321', this.contact, {}).subscribe(data => {
            this.fb.contact();
            this.contactSent = true;
            this.contact = {
              name: '',
              message: ''
            };
          });
        } else {
          //Tommy, if you wanna show a captcha error message, it's here.
        }
      });
    }
  }

  signUpToMC() {
    this.http.post('/contact/mc', this.newsletter, {}).subscribe(data => {
      console.log('subscribed');
      this.fb.lead();
      this.newsletter = {
        email: '',
        company: '',
        firstname: '',
        lastname: ''
      };
      this.subscribed = true;
    });
  }

  addRecaptchaScript() {

    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function (d, s, id, obj) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));

  }

  renderReCaptcha() {
    var captchas = document.getElementsByClassName("recaptcha");
    for (var i = 0; i < captchas.length; i++) {
      window['grecaptcha'].render(captchas[i], {
        'sitekey': '6Lfk4r8UAAAAANyYYl7MISnREoIqR2GdzS5jcjHf',
        'callback': (response) => {
          this.globals.recaptchaToken = response;
        }
      });
    }

    // window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
    //   'sitekey': '6Lfk4r8UAAAAANyYYl7MISnREoIqR2GdzS5jcjHf',
    //   'callback': (response) => {
    //     this.recaptchaToken = response;
    //   }
    // });
  }
}
