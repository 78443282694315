import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  public show1 = false;
  public show2 = false;
  public show3 = false;
  public listOfPartners = [];
  public news: any = [];
  public opportunities: any = [];
  public events: any = [];
  public settings: any = [];
  public partnersPosition = 0;
  nextTemplate: any;
  //@ViewChild(Ng2Carouselamos) ng2Carouselamos : Ng2Carouselamos;
  //@ViewChild('nextTemplate') nextTemplate : ElementRef;
  @ViewChild('nextPartner') nextPartner: ElementRef<HTMLElement>;

  constructor(private http: ApiService, private router: Router, public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.getNews(1);
    this.getOpportunities(1);
    this.getEvents(1);
    this.getSettings(1);
    // Set Play button position
    setTimeout(function () {
      var height = $('.play-button').parent().height();
      height = (height / 2) - 45;
      $('.fa-play-circle').css('margin-top', height + 'px');
    }, 500);
  }

  ngAfterViewInit() {
    console.log(this.nextPartner);
    this.movePartners();
  }

  getNews(page) {
    this.http.get('/news/front', { page: page })
      .subscribe((data) => {
        this.news = data['news'];
      });
  }

  getOpportunities(page) {
    this.http.get('/opportunities/front', { page: page })
      .subscribe((data) => {
        this.opportunities = data['opportunities'];
      });
  }

  getEvents(page) {
    this.http.get('/events/front', { page: page, upcomingEvents: true })
      .subscribe((data) => {
        this.events = data['events'].sort((a, b) => {
          if (a.eventDate < b.eventDate) {
            return -1;
          } else if (a.eventDate > b.eventDate) {
            return 1;
          } else {
            return 0;
          }
        });
      });
  }

  getSettings(page) {
    this.http.get('/settings', { page: page })
      .subscribe((data: any) => {
        this.settings = data;
        for (let j = 0; j < 2; j++) {
        for (let i = 0; i < this.settings.logos.length; i++) {
          const logo = this.settings.logos[i];
          this.listOfPartners.push(logo.publicLink)
        }
        }
      });
  }

  goTo(model, id) {
    this.router.navigate(['./' + model + '/' + id])
  }

  movePartners() {
    let controller = this;
    
    let el: HTMLElement = controller.nextPartner.nativeElement
    el.click();
    setTimeout(function () {
      controller.movePartners();
    }, 2000); //2000
  }


  onNextPartner(){
    this.partnersPosition++;

    if (Math.ceil(this.partnersPosition / 5) * 5 == this.partnersPosition && ((this.listOfPartners.length - this.partnersPosition) < 6)) {
      for (let i = 0; i < this.settings.logos.length; i++) {
        const logo = this.settings.logos[i];
        this.listOfPartners.push(logo.publicLink)
      }
    }
  }
  
  onPrevPartner(){
    if(this.partnersPosition != 0) this.partnersPosition--;
  }

}
