import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { Globals } from 'src/app/shared/globals';

@Component({
  selector: 'app-single-friend',
  templateUrl: './single.component.html',
  styles: []
})
export class SingleFriendComponent implements OnInit {

  api_route = 'companies';
  id: number;
  private sub: any;
  company : {} = {};
  constructor(private router : Router, private route : ActivatedRoute, private http: ApiService, private globals: Globals) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];

      if (this.id)
        this.getCompany();
    })
  }

  getCompany() {
    this.http.get('/' + this.api_route + '/' + this.id + '/front', 0)
      .subscribe((data) => {
        console.log(data);
        this.company = data;
      }, error => {
        if (error)
          this.router.navigate(['./']);
      });
  }

  goTo(page,id){
    this.router.navigate(['./'+page],{ queryParams: { companyId: id } });
  }

}
