import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { Globals } from 'src/app/shared/globals';

@Component({
  selector: 'app-friends-directories',
  templateUrl: './friends-directories.component.html',
  styleUrls: ['./friends-directories.component.css']
})
export class FriendsDirectoriesComponent implements OnInit {

  rows: any[] = [];
  categories: any[] = [];
  totalPages = 0;
  currentPage = 0;
  searchText = '';
  searchCategory = '';

  constructor(private router: Router, private http: ApiService, private route: ActivatedRoute, private globals: Globals) { }

  ngOnInit() {
    this.getCompanies(1);
    this.getCategories();
  }

  
  getCompanies(page) {
    this.http.get('/companies/front', { limit: 1000 })
      .subscribe((data) => {
        this.rows = data['events'];
        this.totalPages = data['meta']['totalPages'];
        this.currentPage = data['meta']['currentPage'];
      });
  }

  getCategories() {
    this.http.get('/companies/categories', {})
      .subscribe((data: any) => {
        this.categories = data;
      });
  }

  search() {
    let filter :any = {};
    
    if(this.searchText){
      filter.searchText = this.searchText
    }
    
    if(this.searchCategory){
      filter.searchCategory = this.searchCategory
    }

    this.http.get('/companies/search', filter)
      .subscribe((data: any) => {
        this.rows = data;
      });
  }

  clearSearch() {
    let filter :any = {};
    this.searchText = null;
    this.searchCategory = null;
    this.getCompanies(1);
  }

  goTo(id) {
    this.router.navigate(['./' + id], { relativeTo: this.route })
  }
}

