import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgDatepickerModule } from 'ng2-datepicker';
import { TooltipModule } from 'ng2-tooltip-directive';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { AboutComponent } from './about/about.component';
import { NewsComponent } from './news/news.component';
import { EventsComponent } from './events/events.component';
import { ResourcesComponent } from './resources/resources.component';
import { OpportunitiesComponent } from './opportunities/opportunities.component';
import { JobsComponent } from './jobs/jobs.component';
import { FriendsDirectoriesComponent } from './friends-directories/friends-directories.component';
import { ContactComponent } from './contact/contact.component';
import { Globals } from './shared/globals';
import { AuthGuard } from './shared/auth.guard';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './shared/jwt.interceptor';
import { SingleNewsComponent } from './news/single/single.component';
import { SingleEventComponent } from './events/single/single.component';
import { SingleOpportunityComponent } from './opportunities/single/single.component';
import { SingleResourceComponent } from './resources/single/single.component';
import { SingleJobComponent } from './jobs/single/single.component';
import { FormsModule } from '@angular/forms';
import { SingleFriendComponent } from './friends-directories/single/single.component';
import { SinglePageComponent } from './pages/single/single.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SettingsComponent } from './settings/settings.component';
import { InviteComponent } from './invite/invite.component';
import { JoinComponent } from './join/join.component';
import { JoinFormComponent } from './join-form/join-form.component';
import { DistributorComponent } from './join/distributor/distributor.component';
import { FriendsComponent } from './join/friends/friends.component';
import { RightsholderComponent } from './join/rightholders/rightholders.component';
import { JoinNowComponent } from './join-now/join-now.component';
import { NewOpportunityComponent } from './opportunities/new/new.component';
import { ApplyOpportunityComponent } from './opportunities/apply/apply.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { CollapsibleModule } from 'angular2-collapsible';
import { ResponsesComponent } from './settings/responses/responses.component';
import { SingleResponseComponent } from './settings/single-response/single-response.component';
import { ApplicationsComponent } from './settings/applications/applications.component';
import { SingleApplicationComponent } from './settings/single-application/single-application.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SanitizeHtmlPipe } from './shared/sanitizeHtml.pipe';
import { NewJobComponent } from './jobs/new/new.component';
import { ExternalLinksDirective } from './shared/externalLinks.pipe';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    AboutComponent,
    NewsComponent,
    EventsComponent,
    ResourcesComponent,
    OpportunitiesComponent,
    JobsComponent,
    FriendsDirectoriesComponent,
    ContactComponent,
    SingleNewsComponent,
    SingleEventComponent,
    SingleOpportunityComponent,
    SingleResourceComponent,
    SingleJobComponent,
    SingleFriendComponent,
    SinglePageComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SettingsComponent, 
    InviteComponent,
    JoinComponent,
    JoinFormComponent,
    DistributorComponent,
    FriendsComponent,
    RightsholderComponent,
    JoinNowComponent,
    NewOpportunityComponent,
    ApplyOpportunityComponent, 
    SetPasswordComponent, 
    ResponsesComponent,
    SingleResponseComponent,
    ApplicationsComponent,
    SingleApplicationComponent,
    SanitizeHtmlPipe,
    ExternalLinksDirective,
    NewJobComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    Ng2CarouselamosModule,
    FormsModule,
    NgxSmartModalModule.forRoot(),
    NgDatepickerModule,
    CollapsibleModule,
    CKEditorModule,
    TooltipModule
  ],
  providers: [Globals, AuthGuard, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
