import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { FacebookService } from 'src/app/shared/facebook.service';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styles: []
})
export class SingleNewsComponent implements OnInit {
  api_route = 'news';
  id: number;
  private sub: any;
  news: {} = {};
  relatedNews: [] = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: ApiService,
    private fb: FacebookService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(queryParams => {
        this.id = params['id'];

        if (this.id) this.getNews(queryParams);

        this.getRelatedNews();
      });
    });
  }

  getNews(queryParams) {
    this.http.get('/' + this.api_route + '/' + this.id, 0)
      .subscribe(data => {
        if (
          data['published'] ||
          (!data['published'] && queryParams['viewPreview'])
        ) {
          this.news = data;
          // new mode script: This is temporary
          if (data['_id'] == '6197ca0b6eb64a30618460ca') {
            let script = this._renderer2.createElement('script');
            // script.type = `application/ld+json`;
            script.text = `
            (function (n, e, w, m, o, d) {
              m = n.createElement(e); m.async = 1; m.src = w;
              o = n.getElementsByTagName(e)[0]; o.parentNode.insertBefore(m, o);
          })(document, 'script', '//engage.newmode.net/embed/43179/43220.js');
        `;

            this._renderer2.appendChild(this._document.body, script);
          }
          //this.news.body = this.news.body.replace('<p>&nbsp;</p>', '');
          this.fb.viewContent({
            content_name: this.id,
            content_category: 'news'
          });
        } else {
          this.router.navigate(['/news']);
        }
      }, error => {
        if (error)
          this.router.navigate(['./']);
      });
  }

  getRelatedNews() {
    this.http
      .get('/' + this.api_route + '/front', { page: 1 })
      .subscribe(data => {
        this.relatedNews = data['news'];
      });
  }

  goTo(id) {
    this.router.navigate(['./news/' + id]);
  }
}
