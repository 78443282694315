import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { Globals } from '../shared/globals';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-join-now',
  templateUrl: './join-now.component.html',
  styleUrls: ['./join-now.component.css']
})
export class JoinNowComponent implements OnInit {

  user = {
    companyName: '',
    companyType: '',
    email: ''
  };

  constructor(private route: ActivatedRoute, private router: Router, private http: ApiService, private globals: Globals, public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.user.companyType = params['type'];
    });
  }


  join() {
    this.http.post('/users/join', this.user, {})
      .subscribe((data) => {
        
      });
  }
}
