import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { Globals } from '../shared/globals';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
  user = {
    password: '',
    passwordVerification: '',
    token: ''
  };
  error = '';
  success = '';

  sub: any;


  constructor(private router: Router, private http: ApiService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.user['token'] = params['token'];
    });
  }

  onSubmit() {
    this.error = '';
    this.success = '';

    if(!this.testPassword(this.user.password)) {
      this.error = 'Passwords must be at least 8 characters, contain an upper case letter, lower case letter and a special character (!&*, etc)';
    } else if (this.user.password != this.user.passwordVerification) {
      this.error = 'Passwords don\'t match';
    } else {
      this.http.post('/users/reset_password', this.user, {})
        .subscribe((data) => {
          if (data['errorNotFound']) {
            this.success = '';
            this.error = 'You have already set your password. Please try logging in or reset your password again.';
          } else {
            this.error = '';
            this.success = 'Password reset successfully.';
            this.router.navigate(['authentication/signin']);
          }
        });
    }
  }

  testPassword(value) {
    var specialCharacters = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if((/[a-z]/.test(value)) && (/[A-Z]/.test(value)) && (/[0-9]/.test(value)) && (specialCharacters.test(value)) && value.length > 7) {
      return true;
    } else {
      return false;
    }
  }
}
