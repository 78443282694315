import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { Globals } from 'src/app/shared/globals';
import { DatepickerOptions } from 'ng2-datepicker';
import { HttpEventType } from '@angular/common/http';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-new-job',
  templateUrl: './new.component.html',
  styles: []
})
export class NewJobComponent implements OnInit {

  api_route = 'jobs';
  id: number;
  private sub: any;
  job = { body: '' };
  posted: boolean;
  preview: boolean;
  apply: boolean;
  restrictUpdate: boolean;
  options: DatepickerOptions = {
    minYear: 1970,
    barTitleIfEmpty: 'Click to select a date'
  };
  addingField = false;
  fieldToAdd = {
    fieldName: '',
    fieldDescription: '',
    fieldType: '',
    selectOptions: [],
    mandatory: false
  }
  Editor = ClassicEditor;
  files = '';
  errors = [];
  constructor(private router: Router, private route: ActivatedRoute, private http: ApiService, private globals: Globals) { }

  ngOnInit() {
    this.restrictUpdate = this.route.snapshot.queryParamMap.get("restrictUpdate") == 'true' ? true : false;
    if (this.restrictUpdate) {
      this.preview = true;
    }
    this.job['companyId'] = this.globals.companyId;

    this.sub = this.route.params.subscribe(params => {

      this.id = params['id'];

      if (this.id)
        this.getJob();
    })
  }

  save() {
    this.job['companyId'] = this.globals.companyId;
    if (this.checkMandatory()) {
      if (this.id) {
        this.http.put('/' + this.api_route + '/' + this.id, this.job, 0)
          .subscribe((data) => {
            this.router.navigate(['./settings'])
          });
      } else {
        this.http.post('/' + this.api_route, this.job, 0)
          .subscribe((data: any) => {
            //this.router.navigate(['./' + this.api_route])
            window.scrollTo(0, 0);
            this.posted = true;

            //Email Sophie about it.
            this.http.post(`/${this.api_route}/job-posted-email`, { id: data._id }, {}).subscribe();
          })
      }
    }
  }

  publishDraft() {
    this.job['companyId'] = this.globals.companyId;
    if (this.checkMandatory()) {
      this.job['draft'] = false;
      if (this.id) {
        this.http.put('/' + this.api_route + '/' + this.id, this.job, 0)
          .subscribe((data) => {
            window.scrollTo(0, 0);
            this.router.navigate(['./settings'])
          });
      } else {
        this.http.post('/' + this.api_route, this.job, 0)
          .subscribe((data: any) => {
            //this.router.navigate(['./' + this.api_route])
            window.scrollTo(0, 0);
            this.posted = true;
          });
      }
    }
  }

  saveDraft() {
    this.job['companyId'] = this.globals.companyId;
    this.job['draft'] = true;
    if (this.id) {
      this.http.put('/' + this.api_route + '/' + this.id, this.job, 0)
        .subscribe((data) => {
          this.router.navigate(['./settings'])
        });
    } else {
      this.http.post('/' + this.api_route, this.job, 0)
        .subscribe((data) => {
          this.router.navigate(['./settings'])
        });
    }
  }

  getJob() {
    this.http.get('/' + this.api_route + '/' + this.id, {})
      .subscribe((data: any) => {
        this.job = data;
        console.log(data);
      });
  }

  // addField() {
  //   this.job.customFields.push(this.fieldToAdd);
  //   this.fieldToAdd = {
  //     fieldName: '',
  //     fieldDescription: '',
  //     fieldType: '',
  //     selectOptions: [],
  //     mandatory: false,
  //   };
  //   //this.addingField = false;
  // }

  addTag(name) {
    return name;
  }

  // removeField(field) {
  //   var index = this.opportunity.customFields.indexOf(field);
  //   this.opportunity.customFields.splice(index, 1);
  // }

  uploadFile(files: FileList) {
    this.globals.progressBars[this.id] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      this.globals.progressBars[this.id].push(0);

      this.http.post('/' + this.api_route + '/' + this.id + '/upload/image', { filename: file.name, type: file.type }, {})
        .subscribe((data) => {
          let formData = new FormData();
          formData.append('key', data['credentials']['fields']['key']);
          formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
          formData.append('acl', data['credentials']['fields']['acl']);
          formData.append('policy', data['credentials']['fields']['policy']);
          formData.append('signature', data['credentials']['fields']['signature']);
          formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
          formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
          formData.append('file', file);

          this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
            if (response.type === HttpEventType.UploadProgress) {
              this.globals.progressBars[this.id][i] = Math.round(100 * response.loaded / response.total);
            } else if (response.type === HttpEventType.Response) {

              this.globals.progressBars[this.id][i] = 0;



              //Save image to model
              if (!this.job['files']) {
                this.job['files'] = [];
              }

              this.job['files'].push({
                name: file.name,
                url: JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'))
              });

              if (this.files == '') {
                this.files += file.name;
              } else {
                this.files += ', ' + file.name;
              }
            }
          });
        });
    }
  }

  checkMandatory() {
    this.errors = [];

    if (!this.job['title']) {
      this.errors.push('Job Title is required')
    }
    if (!this.job['publishDate']) {
      this.errors.push('Publish Date is required')
    }


    return this.errors.length == 0;
  }

  togglePreview() {
    this.preview = !this.preview;
    window.scrollTo(0, 0);
  }

  toggleApplication() {
    this.apply = !this.apply;
    window.scrollTo(0, 0);
  }

  uploadImage(files: FileList) {
    let file = files.item(0);

    this.http.post('/' + this.api_route + '/' + this.id + '/upload/image', { filename: file.name, type: file.type }, {})
      .subscribe((data) => {
        let formData = new FormData();
        formData.append('key', data['credentials']['fields']['key']);
        formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
        formData.append('acl', data['credentials']['fields']['acl']);
        formData.append('policy', data['credentials']['fields']['policy']);
        formData.append('signature', data['credentials']['fields']['signature']);
        formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
        formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
        formData.append('file', file);

        if (!this.globals.progressBars[this.id])
          this.globals.progressBars[this.id] = []

        let globalsLength = this.globals.progressBars[this.id].length;

        this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {

          if (response.type === HttpEventType.UploadProgress) {

            this.globals.progressBars[this.id][globalsLength + 1] = Math.round(100 * response.loaded / response.total);

          } else if (response.type === HttpEventType.Response) {

            this.globals.progressBars[this.id][globalsLength + 1] = 0;


            //Save image to model
            this.job['image'] = JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'));
          }
        });
      });
  }
}
