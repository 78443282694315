import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { Globals } from 'src/app/shared/globals';
import { DatepickerOptions } from 'ng2-datepicker';
import { HttpEventType } from '@angular/common/http';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-new-opportunity',
  templateUrl: './new.component.html',
  styles: []
})
export class NewOpportunityComponent implements OnInit {

  api_route = 'opportunities';
  id: number;
  private sub: any;
  opportunity = { customFields: [], body: '', requirements: '', draft: false, instructions: "" };
  posted: boolean;
  preview: boolean;
  apply: boolean;
  restrictUpdate: boolean;
  options: DatepickerOptions = {
    minYear: 1970,
    barTitleIfEmpty: 'Click to select a date'
  };
  addingField = false;
  fieldToAdd = {
    fieldName: '',
    fieldDescription: '',
    fieldType: '',
    selectOptions: [],
    mandatory: false
  }
  Editor = ClassicEditor;
  files = '';
  errors = [];
  constructor(private router: Router, private route: ActivatedRoute, private http: ApiService, private globals: Globals) { }

  ngOnInit() {
    this.restrictUpdate = this.route.snapshot.queryParamMap.get("restrictUpdate") == 'true' ? true : false;
    if (this.restrictUpdate) {
      this.preview = true;
    }
    this.opportunity['companyId'] = this.globals.companyId;

    this.sub = this.route.params.subscribe(params => {

      this.id = params['id'];

      if (this.id)
        this.getOpportunity();
    })
  }

  save() {
    this.opportunity['companyId'] = this.globals.companyId;
    if (this.checkMandatory()) {
      if (this.id) {
        this.http.put('/' + this.api_route + '/' + this.id, this.opportunity, 0)
          .subscribe((data) => {
            this.router.navigate(['./settings'])
          });
      } else {
        this.http.post('/' + this.api_route, this.opportunity, 0)
          .subscribe((data) => {
            //this.router.navigate(['./' + this.api_route])
            this.http.get('/' + this.api_route + '/' + data['_id'] + '/trigger_email', {})
              .subscribe((data) => {
                window.scrollTo(0, 0);
                this.posted = true;
              })
          })
      }
    }
  }

  publishDraft() {
    this.opportunity['companyId'] = this.globals.companyId;
    if (this.checkMandatory()) {
      this.opportunity.draft = false;
      if (this.id) {
        this.http.put('/' + this.api_route + '/' + this.id, this.opportunity, 0)
          .subscribe((data) => {
            this.http.get('/' + this.api_route + '/' + this.id + '/trigger_email', {})
              .subscribe((data) => {
                window.scrollTo(0, 0);
                this.router.navigate(['./settings'])
              })
          });
      } else {
        this.http.post('/' + this.api_route, this.opportunity, 0)
          .subscribe((data) => {
            //this.router.navigate(['./' + this.api_route])
            window.scrollTo(0, 0);
            this.posted = true;
            console.log(data);
            this.http.get('/' + this.api_route + '/' + data['_id'] + '/trigger_email', {})
              .subscribe((data) => {
                window.scrollTo(0, 0);
                this.posted = true;
              })
          });
      }
    }
  }

  saveDraft() {
    this.opportunity['companyId'] = this.globals.companyId;
    this.opportunity.draft = true;
    if (this.id) {
      this.http.put('/' + this.api_route + '/' + this.id, this.opportunity, 0)
        .subscribe((data) => {
          this.router.navigate(['./settings'])
        });
    } else {
      this.http.post('/' + this.api_route, this.opportunity, 0)
        .subscribe((data) => {
          this.router.navigate(['./settings'])
        });
    }
  }

  getOpportunity() {
    this.http.get('/' + this.api_route + '/' + this.id, {})
      .subscribe((data: any) => {
        this.opportunity = data;
      });
  }

  addField() {
    this.opportunity.customFields.push(this.fieldToAdd);
    this.fieldToAdd = {
      fieldName: '',
      fieldDescription: '',
      fieldType: '',
      selectOptions: [],
      mandatory: false,
    };
    //this.addingField = false;
  }

  addTag(name) {
    return name;
  }

  removeField(field) {
    var index = this.opportunity.customFields.indexOf(field);
    this.opportunity.customFields.splice(index, 1);
  }

  uploadFile(files: FileList) {
    this.globals.progressBars[this.id] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      this.globals.progressBars[this.id].push(0);

      this.http.post('/' + this.api_route + '/' + this.id + '/upload/image', { filename: file.name, type: file.type }, {})
        .subscribe((data) => {
          let formData = new FormData();
          formData.append('key', data['credentials']['fields']['key']);
          formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
          formData.append('acl', data['credentials']['fields']['acl']);
          formData.append('policy', data['credentials']['fields']['policy']);
          formData.append('signature', data['credentials']['fields']['signature']);
          formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
          formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
          formData.append('file', file);

          this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
            if (response.type === HttpEventType.UploadProgress) {
              this.globals.progressBars[this.id][i] = Math.round(100 * response.loaded / response.total);
            } else if (response.type === HttpEventType.Response) {

              this.globals.progressBars[this.id][i] = 0;



              //Save image to model
              if (!this.opportunity['files']) {
                this.opportunity['files'] = [];
              }

              this.opportunity['files'].push({
                name: file.name,
                url: JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'))
              });

              if (this.files == '') {
                this.files += file.name;
              } else {
                this.files += ', ' + file.name;
              }
            }
          });
        });
    }
  }

  uploadImage(files: FileList) {
    const file = files.item(0);
    this.globals.progressBars['image'] = 0;

    this.http.post('/' + this.api_route + '/' + this.id + '/upload/image', { filename: file.name, type: file.type }, {})
      .subscribe((data) => {
        let formData = new FormData();
        formData.append('key', data['credentials']['fields']['key']);
        formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
        formData.append('acl', data['credentials']['fields']['acl']);
        formData.append('policy', data['credentials']['fields']['policy']);
        formData.append('signature', data['credentials']['fields']['signature']);
        formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
        formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
        formData.append('file', file);

        this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
          if (response.type === HttpEventType.UploadProgress) {
            this.globals.progressBars['image'] = Math.round(100 * response.loaded / response.total);
          } else if (response.type === HttpEventType.Response) {

            this.globals.progressBars['image'] = 0;

            //Save image to model
            this.opportunity['image'] = JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'));

            this.http.post('/' + this.api_route + '/public-link', { key: this.opportunity['image'] }, {}).subscribe((data: any) => {
              this.opportunity['publicImage'] = data;
            })
          }
        });
      });
  }

  checkMandatory() {
    this.errors = [];

    if (!this.opportunity['title'])
      this.errors.push('Opportunity name is required')

    if (!this.opportunity['organizer'])
      this.errors.push('Organiser name is required')

    if (!this.opportunity['publishDate'])
      this.errors.push('Publish date is required')

    if (!this.opportunity['excerpt'])
      this.errors.push('Brief desciption is required')

    if (!this.opportunity['body'])
      this.errors.push('Full name is required')

    if (!this.opportunity['image'])
      this.errors.push('Thumbnail is required')

    if (!this.opportunity['instructions'] && this.opportunity['responseType'] == "Text Instructions") {
      this.errors.push('Text Instructions are required')
    }

    return this.errors.length == 0;
  }

  togglePreview() {
    this.preview = !this.preview;
    window.scrollTo(0, 0);
  }

  toggleApplication() {
    this.apply = !this.apply;
    window.scrollTo(0, 0);
  }
}
