import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { AboutComponent } from './about/about.component';
import { NewsComponent } from './news/news.component';
import { EventsComponent } from './events/events.component';
import { ResourcesComponent } from './resources/resources.component';
import { OpportunitiesComponent } from './opportunities/opportunities.component';
import { JobsComponent } from './jobs/jobs.component';
import { FriendsDirectoriesComponent } from './friends-directories/friends-directories.component';
import { ContactComponent } from './contact/contact.component';
import { SingleNewsComponent } from './news/single/single.component';
import { SingleEventComponent } from './events/single/single.component';
import { SingleOpportunityComponent } from './opportunities/single/single.component';
import { SingleResourceComponent } from './resources/single/single.component';
import { SingleJobComponent } from './jobs/single/single.component';
import { SingleFriendComponent } from './friends-directories/single/single.component';
import { SinglePageComponent } from './pages/single/single.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SettingsComponent } from './settings/settings.component';
import { InviteComponent } from './invite/invite.component';
import { JoinComponent } from './join/join.component';
import { JoinFormComponent } from './join-form/join-form.component';
import { RightsholderComponent } from './join/rightholders/rightholders.component';
import { FriendsComponent } from './join/friends/friends.component';
import { DistributorComponent } from './join/distributor/distributor.component';
import { JoinNowComponent } from './join-now/join-now.component';
import { NewOpportunityComponent } from './opportunities/new/new.component';
import { ApplyOpportunityComponent } from './opportunities/apply/apply.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { ResponsesComponent } from './settings/responses/responses.component';
import { SingleResponseComponent } from './settings/single-response/single-response.component';
import { ApplicationsComponent } from './settings/applications/applications.component';
import { SingleApplicationComponent } from './settings/single-application/single-application.component';
import { NewJobComponent } from './jobs/new/new.component';


const AppRoutes: Routes = [
  { path: '', component: IndexComponent },
  { path: 'index', component: IndexComponent },
  { path: 'about', component: AboutComponent },
  { path: 'news', component: NewsComponent },
  { path: 'news/:id', component: SingleNewsComponent },
  { path: 'events', component: EventsComponent },
  { path: 'events/:id', component: SingleEventComponent },
  { path: 'resources', component: ResourcesComponent },
  { path: 'resources/:id', component: SingleResourceComponent },
  { path: 'opportunities', component: OpportunitiesComponent },
  { path: 'opportunities/view/:id', component: SingleOpportunityComponent },
  { path: 'opportunities/new', component: NewOpportunityComponent },
  { path: 'opportunities/edit/:id', component: NewOpportunityComponent },
  { path: 'opportunities/apply/:id', component: ApplyOpportunityComponent },
  { path: 'jobs', component: JobsComponent },
  { path: 'jobs/new', component: NewJobComponent },
  { path: 'jobs/edit/:id', component: NewJobComponent },
  { path: 'jobs/:id', component: SingleJobComponent },
  { path: 'friends-directories', component: FriendsDirectoriesComponent },
  { path: 'friends-directories/:id', component: SingleFriendComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'settings/responses/:id', component: ResponsesComponent },
  { path: 'settings/applications', component: ApplicationsComponent },
  { path: 'settings/responses/:id/:response', component: SingleResponseComponent },
  { path: 'settings/applications/:id/:response', component: SingleApplicationComponent },
  { path: 'pages/:id', component: SinglePageComponent },
  { path: 'login', component: LoginComponent },
  { path: 'join-form', component: JoinFormComponent },
  { path: 'join-form/:id', component: JoinFormComponent },
  { path: 'abcde', component: JoinFormComponent },
  { path: 'abcde/:id', component: JoinFormComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'set-password/:token', component: SetPasswordComponent },
  { path: 'invite/:token', component: InviteComponent },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

export const AppRoutingModule = RouterModule.forRoot(AppRoutes, {useHash: true});
