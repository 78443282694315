import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { Globals } from '../shared/globals';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {

  rows: any[] = [];
  categories: any[] = [];
  totalPages = 0;
  currentPage = 0;
  searchText = '';
  searchCategory = '';
  searchSortBy = 'Featured';
  totalPerPage = 8;

  companyId;
  filter = {
    searchSortBy: 'Featured',
    searchText: '',
    searchCategory: '',
  };
  constructor(private router: Router, private http: ApiService, private route: ActivatedRoute, private globals: Globals) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if (params.companyId)
          this.companyId = params.companyId;

        this.getCategories();

        this.route.queryParams.subscribe(params => {
          if (params['category']) {
            this.filter['searchCategory'] = params['category'];
            this.search();
          }else{
            this.getResources();
          }
        });
      });

  }

  getResources() {
    this.http.get('/resources/front', { limit: this.totalPerPage, companyId: this.companyId })
      .subscribe((data) => {
        this.rows = data['resources'];
        this.totalPages = data['meta']['totalPages'];
        this.currentPage = data['meta']['currentPage'];
      });
  }

  getCategories() {
    this.http.get('/resources/categories', {})
      .subscribe((data: any) => {
        this.categories = data;
      });
  }

  search() {
    this.http.get('/resources/search', this.filter)
      .subscribe((data: any) => {
        this.rows = data;
      });
  }

  clearSearch() {
    let filter: any = {};
    this.searchText = null;
    this.searchCategory = null
    this.getResources();
  }

  goTo(id) {
    console.log('going to');
    this.router.navigate(['./' + id], { relativeTo: this.route })
  }

  showMore() {
    this.totalPerPage += 8;
    this.getResources();
  }

  sortBy() {
    let filter: any = {};

    if (this.searchText) {
      filter.searchText = this.searchText
    }

    if (this.searchCategory) {
      filter.searchCategory = this.searchCategory
    }

    if (this.searchSortBy) {
      filter.searchSortBy = this.searchSortBy
    }

    this.http.get('/resources/search', filter)
      .subscribe((data: any) => {
        this.rows = data;
      });
  }
}