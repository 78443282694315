import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { Globals } from '../shared/globals';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit {
  user = {
    password: '',
    passwordVerification: '',
    token: ''
  };
  error = '';
  strengthErrors = [];

  success = '';

  sub: any;


  constructor(private router: Router, private http: ApiService, private route: ActivatedRoute, private globals: Globals) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.user['token'] = params['token'];
    });
  }

  onSubmit() {
    this.error = '';
    if (this.user['password'] !== this.user['passwordVerification']) {
      this.error = 'Passwords don\'t match';
    } else if (this.checkPassword()) {
      console.log('sending passwords');
       this.http.post('/users/signup', this.user, {})
          .subscribe((data) => {
            if (data['error']) {
              this.error = 'Invalid invitation link. Please check your invitation email.'
            } else if (data['errorUsed']) {
              this.error = 'This invitation link has already been used.'
            } else {
              localStorage.setItem('token', data['token']);
              this.globals.loggedIn = true;
              this.http.get('/users/current/type', 0)
                .subscribe((data: any) => {
                  this.globals.userType = data;

                  this.http.get('/users/current/company', 0)
                    .subscribe((data: any) => {
                      this.globals.companyId = data._id;
                      this.globals.memberType = data.memberType;
                      this.router.navigate(['/settings']);
                    });
                });
            }
          });
    }
  }

  checkPassword() {
    this.strengthErrors = [];

    let password = this.user['password'];

    if (password.length < 8)
      this.strengthErrors.push('Password should at least have 8 characters.');

    if (!/\d/.test(password))
      this.strengthErrors.push('Password should contain numbers.');

    if (!/[a-z]/.test(password))
      this.strengthErrors.push('Password should contain lowercase letters.');

    if (!/[A-Z]/.test(password))
      this.strengthErrors.push('Password should contain uppercase letters.');

    if (!/\W/.test(password))
      this.strengthErrors.push('Password should contain symbols.');


    if (this.strengthErrors.length == 0)
      return true;
    else
      return false;
  }
}

