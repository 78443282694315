import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService } from 'src/app/shared/api.service';
import { Globals } from 'src/app/shared/globals';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit {
  company = {};
  users = [{}];
  applications = [{}];
  id;
  constructor(private router: Router, private route: ActivatedRoute, private http: ApiService, private globals: Globals, public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.getApplications();
  }

  getApplications() {
    this.http.get('/users/current/applications', 0)
      .subscribe((data: any) => {
        this.applications = data;
      });
  }
  

  goTo(oppId, resId) {
    this.router.navigate(['./settings/applications/'+oppId+'/'+resId])
  }

  goBack(){
    this.router.navigate(['./settings/'])
  }

}
