import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { Globals } from '../shared/globals';

@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.css']
})

export class OpportunitiesComponent implements OnInit {

  rows: any[] = [];
  categories: any[] = [];
  totalPages = 0;
  currentPage = 0;
  searchText = '';
  searchCategory = '';
  searchSortBy = 'Featured';
  totalPerPage = 20;
  companyId;
  filter = {
    searchText: '',
    searchCategory: '',
    searchSortBy: 'Featured',
  };
  constructor(private router: Router, private http: ApiService, private route: ActivatedRoute, private globals: Globals) { }

  ngOnInit() {

    this.route.queryParams
      .subscribe(params => {
        if (params.companyId)

          this.companyId = params.companyId;

        this.getCategories();

        this.route.queryParams.subscribe(params => {
          if (params['category']) {
            this.filter['searchCategory'] = params['category'];
            this.search();
          }else{
            this.getOpportunities();
          }
        });
      });
  }

  getOpportunities() {
    this.http.get('/opportunities/front', { limit: this.totalPerPage, companyId: this.companyId })
      .subscribe((data) => {
        this.rows = data['opportunities'];
        this.totalPages = data['meta']['totalPages'];
        this.currentPage = data['meta']['currentPage'];
      });
  }

  getCategories() {
    this.http.get('/opportunities/categories', {})
      .subscribe((data: any) => {
        this.categories = data;
      });
  }

  search() {
    this.http.get('/opportunities/search', this.filter)
      .subscribe((data: any) => {
        this.rows = data;
      });
  }

  clearSearch() {
    this.filter = {
      searchText: '',
      searchCategory: '',
      searchSortBy: 'Featured',
    };

    this.getOpportunities();
  }

  goTo(id) {
    console.log('going to');
    this.router.navigate(['./' + id], { relativeTo: this.route })
  }

  showMore() {
    this.totalPerPage += 4;
    this.getOpportunities();
  }

  sortBy() {

    this.http.get('/opportunities/search', this.filter)
      .subscribe((data: any) => {
        this.rows = data;
      });
  }

}