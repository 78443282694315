import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { FacebookService } from 'src/app/shared/facebook.service';

@Component({
  selector: 'app-single-page',
  templateUrl: './single.component.html',
  styles: []
})
export class SinglePageComponent implements OnInit {
  api_route = 'pages';
  id: number;
  private sub: any;
  page: {} = {};
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FacebookService,
    private http: ApiService
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(queryParams => {
        this.id = params['id'];

        if (this.id) this.getPage(queryParams);
      });
    });
  }

  getPage(queryParams) {
    this.http.get('/' + this.api_route + '/' + this.id, 0).subscribe(data => {
      console.log(data);
      console.log(this.id);

      if (
        data['published'] ||
        (!data['published'] && queryParams['viewPreview'])
      ) {
        this.page = data;
        this.fb.viewContent({
          content_name: this.id,
          content_category: 'page'
        });
      } else {
        this.router.navigate(['/']);
      }
    });
  }
}
