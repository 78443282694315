import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { FacebookService } from 'src/app/shared/facebook.service';
import { Globals } from 'src/app/shared/globals';

@Component({
  selector: 'app-single-opportunity',
  templateUrl: './single.component.html',
  styles: []
})
export class SingleOpportunityComponent implements OnInit {
  api_route = 'opportunities';
  id: number;
  private sub: any;
  opportunity: {} = {};
  company: {} = {};
  today: any;
  displayApply = false;
  displayExpired = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: ApiService,
    private fb: FacebookService,
    private globals: Globals
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];

      if (this.id) this.getOpportunity();
    });
  }

  getOpportunity() {
    let controller = this;
    this.http.get('/' + this.api_route + '/' + this.id, 0).subscribe(data => {
      this.opportunity = data;
      //if(data) { this.getCompany(data.companyId); }
      this.today = new Date();
      this.fb.viewContent({
        content_name: this.id,
        content_category: 'opportunities'
      });
      // Display Expired
      if (
        controller.globals.loggedIn &&
        controller.globals.memberType != 'Friend of AIM' &&
        controller.opportunity['responseType'] &&
        controller.opportunity['expiryDate'] &&
        new Date(controller.opportunity['expiryDate']) < controller.today
      ) {
        controller.displayExpired = true;
      }
      // Display Apply
      if (
        controller.globals.loggedIn &&
        controller.globals.memberType != 'Friend of AIM' &&
        controller.opportunity['responseType'] &&
        ((controller.opportunity['expiryDate'] &&
          new Date(controller.opportunity['expiryDate']) > controller.today) ||
          !controller.opportunity['expiryDate'])
      ) {
        controller.displayApply = true;
      }
    }, error => {
      if (error)
        this.router.navigate(['./']);
    });
  }

  getCompany(id) {
    if(id) {
      this.http.get('/companies/' + id, 0)
        .subscribe((data) => {
          this.company = data;
        }, error => {
          if (error)
            this.router.navigate(['./']);
        });
    }
  }

  apply(id) {
    this.router.navigate(['./opportunities/apply/' + id]);
  }
}
