import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService } from 'src/app/shared/api.service';
import { Globals } from 'src/app/shared/globals';

@Component({
  selector: 'app-rightholders',
  templateUrl: './rightholders.component.html',
  styleUrls: ['./rightholders.component.css']
})
export class RightsholderComponent implements OnInit {
  settings : any = [];
  constructor(private router: Router, private http: ApiService, private globals: Globals, public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.getSettings(1);
  }

  getSettings(page) {
    this.http.get('/settings', { page: page })
      .subscribe((data : any) => {
        console.log(data)
        this.settings = data;
      });
  }

  joinNow(page) {
    this.router.navigate(['./join-now/'+page])
  }
}
