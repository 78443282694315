import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { Globals } from '../shared/globals';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  company = {};
  users = [{}];
  opportunities = [{}];
  jobs = [{}];
  applications = [{}];
  newUser = {
    email: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    type: 'Standard'
  };
  newUserErrors = {
    firstName: false,
    lastName: false,
    email: false,
    jobTitle: false,
    type: false
  }
  userErrorEmail;
  userToDelete;
  Editor = ClassicEditor;
  categories;
  events;
  news;
  latestResources;
  latestOpportunities;
  companyUpdated = false;
  constructor(private router: Router, private http: ApiService, private route: ActivatedRoute, private globals: Globals, public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.getCompany();
    this.getCategories();
    this.getUsers();
    this.getOpportunities();
    this.getResources();
    this.getJobs();
    this.getApplications();
    this.getEvents();
    this.getNews();
    this.getLatestOpportunities();
    console.log(this.globals.memberType);
  }

  getCompany() {
    this.http.get('/users/current/company', 0)
      .subscribe((data: any) => {
        this.company = data;
      });
  }

  getEvents() {
    this.http.get('/events/front', { limit: 3 })
      .subscribe((data: any) => {
        this.events = data['events'];
      });
  }

  getNews() {
    this.http.get('/news/front', { limit: 3 })
      .subscribe((data: any) => {
        this.news = data['news'];
      });
  }

  getLatestOpportunities() {
    this.http.get('/opportunities/front', { limit: 3 })
      .subscribe((data: any) => {
        this.latestOpportunities = data['opportunities'];
      });
  }

  getCategories() {
    this.http.get('/companies/categories', 0)
      .subscribe((data: any) => {
        this.categories = data;
      });
  }

  getUsers() {
    this.http.get('/users/current/company/users', 0)
      .subscribe((data: any) => {
        this.users = data;
      });
  }

  getOpportunities() {
    this.http.get('/opportunities/company/', 0)
      .subscribe((data: any) => {
        this.opportunities = data;
        console.log(data);
      });
  }

  getResources() {
    this.http.get('/resources/front', { limit: 3 })
      .subscribe((data: any) => {
        console.log(data);
        this.latestResources = data['resources'];
      });
  }

  getJobs() {
    this.http.get('/jobs/company/', 0)
      .subscribe((data: any) => {
        this.jobs = data;
      });
  }

  deleteOpportunity(id) {
    if (confirm("Are you sure to delete this opportunitiy?")) {
      this.http.delete('/opportunities/' + id, 0)
        .subscribe((data) => {
          this.getOpportunities();
        });
    }
  }

  deleteJob(id) {
    if (confirm("Are you sure to delete this job?")) {
      this.http.delete('/jobs/' + id, 0)
        .subscribe((data) => {
          this.getJobs();
        });
    }
  }


  save() {
    this.companyUpdated = false;
    let controller = this;
    setTimeout(function () {
      controller.http.put('/companies/' + controller.company['_id'], controller.company, 0)
        .subscribe((data: any) => {
          controller.company = data;
          controller.companyUpdated = true;
          controller.http.get('/companies/' + controller.company['_id'] + '/update_email', {}).subscribe((data: any) => {
            console.log(data);
          })
        });
    }, 500);
  }

  deleteUser(id) {
    this.http.delete('/users/' + id, 0)
      .subscribe((data: any) => {
        this.getUsers();
      });
  }

  invite(modal) {
    this.newUser['companyId'] = this.company['_id'];
    let error = false;
    this.newUserErrors = {
      firstName: false,
      lastName: false,
      email: false,
      jobTitle: false,
      type: false
    }

    if (!this.newUser.email) { this.newUserErrors.email = true; error = true; }
    if (!this.newUser.jobTitle) { this.newUserErrors.jobTitle = true; error = true; }
    if (!this.newUser.type) { this.newUserErrors.type = true; error = true; }
    if (!this.newUser.firstName) { this.newUserErrors.firstName = true; error = true; }
    if (!this.newUser.lastName) { this.newUserErrors.lastName = true; error = true; }

    if (!error) {
      modal.close();
      this.http.post('/users/invite', this.newUser, 0)
        .subscribe((data: any) => {
          this.newUser = {
            email: '',
            firstName: '',
            lastName: '',
            jobTitle: '',
            type: 'Standard'
          }
          this.userErrorEmail = null;
        }, (error) => {
          console.log(error);
          this.userErrorEmail = this.newUser.email;
          this.newUser = {
            email: '',
            firstName: '',
            lastName: '',
            jobTitle: '',
            type: 'Standard'
          }
        });
    }
  }

  getApplications() {
    this.http.get('/users/current/applications', 0)
      .subscribe((data: any) => {
        this.applications = data;
        console.log(data);
      });
  }

  goTo(id) {
    this.router.navigate(['./responses/' + id], { relativeTo: this.route })
  }

  goToResponse(id, applicationId) {
    this.router.navigate(['./responses/' + id + '/' + applicationId], { relativeTo: this.route })
  }

  goOpportunities() {
    this.router.navigate(['./opportunities/new'])
  }

  createJob() {
    this.router.navigate(['./jobs/new'])
  }

  goApplications() {
    this.router.navigate(['./settings/applications'])
  }

  editOpportunity(id) {
    this.router.navigate(['./opportunities/edit/' + id])
  }

  editJob(id) {
    this.router.navigate(['./jobs/edit/' + id])
  }

  addTag(name) {
    return name;
  }

  uploadLogo(files: FileList) {
    let file = files.item(0);

    this.http.post('/companies/' + this.company['_id'] + '/upload/logo', { filename: file.name, type: file.type }, {})
      .subscribe((data) => {
        let formData = new FormData(data['credentials']['fields']);
        formData.append('key', data['credentials']['fields']['key']);
        formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
        formData.append('acl', data['credentials']['fields']['acl']);
        formData.append('policy', data['credentials']['fields']['policy']);
        formData.append('signature', data['credentials']['fields']['signature']);
        formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
        formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
        formData.append('file', file);

        this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
          if (response.type === HttpEventType.UploadProgress) {
            this.globals.progressBars[this.company['_id']] = Math.round(100 * response.loaded / response.total);
          } else if (response.type === HttpEventType.Response) {

            this.globals.progressBars[this.company['_id']] = 0;


            //Save image to model
            this.company['image'] = JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'));
            this.http.put('/companies/' + this.company['_id'], this.company, {})
              .subscribe((dataReleases) => {
                this.getCompany();
              });
          }
        });
      });
  }
}
