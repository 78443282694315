import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService } from 'src/app/shared/api.service';
import { Globals } from 'src/app/shared/globals';

@Component({
  selector: 'app-single-response',
  templateUrl: './single-response.component.html',
  styleUrls: ['./single-response.component.css']
})
export class SingleResponseComponent implements OnInit {
  response;
  id;
  opportunityId;
  opportunity;
  objectKeys = Object.keys;
  constructor(private router: Router, private route: ActivatedRoute, private http: ApiService, private globals: Globals, public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['response'];
      this.opportunityId = params['id'];

      this.getResponse();
      this.getOpportunity();
    })
  }

  getResponse() {
    this.http.get('/responses/'+this.id, 0)
      .subscribe((data: any) => {
        this.response = data;
      });
  }

  getOpportunity() {
    this.http.get('/opportunities/'+this.opportunityId, 0)
      .subscribe((data: any) => {
        this.opportunity = data;
      });
  }

  goTo(responseId) {
    this.router.navigate(['./'+responseId], { relativeTo: this.route })
  }

  goBack(){
    this.router.navigate(['./settings/responses/'+this.opportunityId])
  }

  thumbsUp(response) {
    response['userStatus'] = response['userStatus'] == "thumbs up" ? null : "thumbs up";
    this.http.put('/responses/' + response._id, response, {})
      .subscribe((data: any) => {
        console.log(data);
      });
  }

  thumbsDown(response) {
    response['userStatus'] = response['userStatus'] == "thumbs down" ? null : "thumbs down";
    this.http.put('/responses/' + response._id, response, {})
      .subscribe((data: any) => {
        console.log(data);
      });
  }
}
