import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { FacebookService } from 'src/app/shared/facebook.service';
import { Globals } from 'src/app/shared/globals';

@Component({
  selector: 'app-single-event',
  templateUrl: './single.component.html',
  styles: []
})
export class SingleEventComponent implements OnInit {
  api_route = 'events';
  id: number;
  private sub: any;
  event: {} = {};
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: ApiService,
    private fb: FacebookService,
    private globals: Globals
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(queryParams => {
        this.id = params['id'];

        if (this.id) this.getEvent(queryParams);
      });
    });
  }

  getEvent(queryParams) {
    this.http.get('/' + this.api_route + '/' + this.id, 0).subscribe(data => {
      if (
        data['published'] ||
        (!data['published'] && queryParams['viewPreview'])
      ) {
        this.event = data;
        this.fb.viewContent({
          content_name: this.id,
          content_category: 'event'
        });
        // Fix external url
        if (this.event && this.event['externalLink'] && !this.event['externalLink'].includes('http')) {
          this.event['externalLink'] = 'https://' + this.event['externalLink'];
        }
      } else {
        this.router.navigate(['/events']);
      }
    }, error => {
      if (error)
        this.router.navigate(['./']);
    });
  }
}
