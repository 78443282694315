import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { Globals } from '../shared/globals';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user: any = {
    email: '',
    password: ''
  };
  error = '';
  hideForm = false;
  setupMessage = '';
  constructor(private router: Router, private http: ApiService, private globals: Globals) { }

  ngOnInit() { }

  onSubmit() {
    this.http.post('/users/signin', this.user, {})
      .subscribe((data) => {
        if (data) {
          localStorage.setItem('token', data['token']);
          this.http.get('/users/current/type', 0)
            .subscribe((data: any) => {
              this.globals.userType = data;
              if(data == "Internal") {
                this.globals.loggedIn = true;
                //this.globals.companyId = data._id;
                this.globals.memberType = "Rightsholder";
                window.location.href = 'https://admin.aim.org.uk';
              } else {
                this.http.get('/users/current/company', 0)
                  .subscribe((data: any) => {
                    this.globals.loggedIn = true;
                    this.globals.companyId = data._id;
                    this.globals.memberType = data.memberType;
                    this.router.navigate(['/settings']);
                  }, err => {
                    this.error = "The user isn't associated with a company. Please contact AIM to fix the issue and gain access to the site.";
                  });
              }
            });

        }
      }, err => {
        console.log("Signin Errored");
        console.log(err);
        if (err.error.failed == 'No password') {
          this.hideForm = true;
          this.setupMessage = `Since this is the first time you're logging in at our new site, we require you to create a new password. An email has been sent to you to complete your setup`;
          this.http.post('/users/set_password', this.user, {})
            .subscribe((data) => { });
        } else {
          
          this.error = err.error.failed;
        }
      });
  }

  reset() {
    this.router.navigate(['/forgot-password']);
  }
}
