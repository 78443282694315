import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {

  rows: any[] = [];
  categories: any[] = [];
  locations: any[] = [];
  sectors: any[] = [];
  totalPages = 0;
  currentPage = 0;
  searchCategory = '';
  searchLocation = '';
  searchSector = '';
  totalPerPage=20;

  constructor(private router: Router, private http: ApiService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getJobs();
    this.getCategories();
    this.getLocations();
    this.getSectors();
  }

  getJobs() {
    this.http.get('/jobs/front', { limit: this.totalPerPage, orderBy: "featuredOrder", orderDir: "asc" })
      .subscribe((data) => {
        this.rows = data['jobs'];
        this.totalPages = data['meta']['totalPages'];
        this.currentPage = data['meta']['currentPage'];
      });
  }

  getCategories() {
    this.http.get('/jobs/categories', {})
      .subscribe((data : any) => {
        this.categories = data;
      });
  }
  
  getLocations() {
    this.http.get('/jobs/locations', {})
      .subscribe((data : any) => {
        this.locations = data;
      });
  }
  
  getSectors() {
    this.http.get('/jobs/sectors', {})
      .subscribe((data : any) => {
        this.sectors = data;
      });
  }
  
  search() {
    let filter :any = {};
    
    if(this.searchSector){
      filter.searchSector = this.searchSector
    }
    
    if(this.searchLocation){
      filter.searchLocation = this.searchLocation
    }
    
    if(this.searchCategory){
      filter.searchCategory = this.searchCategory
    }

    filter.orderBy = "featuredOrder"
    filter.orderDir = "asc"
    this.http.get('/jobs/search', filter)
      .subscribe((data : any) => {
        this.rows = data;
      });
  }

  clearSearch() {
    let filter: any = {};
    this.searchSector = null;
    this.searchLocation = null;
    this.searchCategory = null;
    this.getJobs();
  }
  
  goTo(id) {
    console.log('going to');
    this.router.navigate(['./' + id], { relativeTo: this.route })
  }

  showMore(){
    this.totalPerPage+= 4;
    this.getJobs();
  }
  
  limitExcerpt(excerpt){
    return excerpt && excerpt.length < 200 ? excerpt : excerpt.substring(0, 200)+'...';
  }
}
