import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/api.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  rows: any[] = [];
  categories: any[] = [];
  locations: any[] = [];
  totalPages = 0;
  currentPage = 0;
  searchText = '';
  searchCategory = '';
  searchLocation = '';
  searchSortBy = 'Featured';
  totalPerPage = 6;
  showMoreButton = true;
  filter = { totalPerPage: 6, upcomingEvents: true, searchSortBy: 'Featured' };

  pastEventsSelected = false;
  upcomingEventsSelected = true;

  constructor(private router: Router, private http: ApiService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getCategories();
    this.getLocations();

    this.route.queryParams.subscribe(params => {
      if (params['category']) {
        this.filter['searchCategory'] = params['category'];
        this.search();
      } else {
        this.getUpcomingEvents()
      }
    });

  }

  getCategories() {
    this.http.get('/events/categories', {})
      .subscribe((data: any) => {
        this.categories = data;
      });
  }

  getLocations() {
    this.http.get('/events/locations', {})
      .subscribe((data: any) => {
        this.locations = data;
      });
  }

  search() {
    this.http.get('/events/search', this.filter)
      .subscribe((data: any) => {
        this.rows = data;
        if (this.rows.length != this.totalPerPage) {
          this.showMoreButton = false;
        } else {
          this.showMoreButton = true;
        }
      });
  }

  clearSearch() {
    this.filter['searchText'] = null;
    this.filter['searchCategory'] = null;
    this.filter['searchLocation'] = null;
    this.search();
  }

  getPastEvents() {
    this.pastEventsSelected = true;
    this.upcomingEventsSelected = false;

    this.filter['pastEvents'] = true;
    delete this.filter['upcomingEvents'];
    this.search();
  }

  getUpcomingEvents() {
    this.pastEventsSelected = false;
    this.upcomingEventsSelected = true;

    delete this.filter['pastEvents'];
    this.filter['upcomingEvents'] = true;
    this.search();
  }

  goTo(id) {
    console.log('going to');
    this.router.navigate(['./' + id], { relativeTo: this.route })
  }

  showMore() {
    this.filter['totalPerPage'] += 6;
    this.search();
  }

  getEventDate(event) {
    let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    let startDate;
    let endDate;

    if (event.eventDate)
      startDate = new Date(event.eventDate).toLocaleDateString("en-UK", options);
    if (event.endDate)
      endDate = new Date(event.endDate).toLocaleDateString("en-UK", options);

    let date = '';
    if (startDate)
      date += startDate;
    if (event.endDate && startDate != endDate)
      date += ' - ' + endDate;

    return date;
  }

}
