import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/api.service';
import { Globals } from '../shared/globals';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  user = {
    email: ''
  }

  error = ''
  success = ''

  @ViewChild('recaptcha') recaptchaElement: ElementRef;
  constructor(private router: Router, private http: ApiService, private globals: Globals) { }

  ngOnInit() {
    // this.addRecaptchaScript();
  }
  
  ngAfterViewInit() {
    this.renderReCaptcha();
  }

  onSubmit() {
    this.error = '';
    this.success = '';

    this.http.post('/contact/captcha', { token: this.globals.recaptchaToken }, {}).subscribe(data => {
      this.globals.recaptchaToken = '';
      if (data['response'] && data['response'] == true) {
        console.log('Sending Forgot Password')
        this.http.post('/users/forgot_password', this.user, {})
          .subscribe((data) => {
            if (data['errorNotFound']) {
              this.success = '';
              this.error = data['errorNotFound'];
            } else {
              this.error = '';
              this.success = 'Password reset email sent successfully.';
            }
          });
      } else {
        //Tommy, if you wanna show a captcha error message, it's here.
      }
    });
  }


  renderReCaptcha() {
    var captchas = document.getElementsByClassName("recaptcha");
    for (var i = 0; i < captchas.length; i++) {
      window['grecaptcha'].render(captchas[i], {
        'sitekey': '6Lfk4r8UAAAAANyYYl7MISnREoIqR2GdzS5jcjHf',
        'callback': (response) => {
          this.globals.recaptchaToken = response;
        }
      });
    }
  }
}
