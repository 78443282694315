import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  rows: any[] = [];
  categories: any[] = [];
  totalPages = 0;
  currentPage = 0;
  searchText = '';
  searchCategory = '';
  searchSortBy = 'Featured';
  hideShowMore = false;
  totalPerPage = 11;
  filter = {
    searchText: '',
    searchCategory: '',
    private: { '$ne': true },
    searchSortBy: 'Featured'
  };
  constructor(private router: Router, private http: ApiService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getCategories();

    this.route.queryParams.subscribe(params => {
      if (params['category']) {
        this.filter.searchCategory = params['category'];
        this.search();
      } else {
        this.getNews();
      }
    });

  }

  getNews() {
    this.http.get('/news/front', { limit: this.totalPerPage, searchSortBy: "Featured" })
      .subscribe((data) => {
        this.rows = data['news'];
        this.totalPages = data['meta']['totalPages'];
        this.currentPage = data['meta']['currentPage'];
      });
  }

  search() {
    this.http.get('/news/search', this.filter)
      .subscribe((data: any) => {
        this.rows = data;
        this.hideShowMore = true;
      });
  }

  clearSearch() {
    this.filter = {
      searchText: '',
      searchCategory: '',
      private: { '$ne': true },
      searchSortBy: 'Featured'
    };
    this.getNews();
  }

  getCategories() {
    this.http.get('/news/categories', {})
      .subscribe((data: any) => {
        this.categories = data;
      });
  }

  goTo(id) {
    console.log('going to');
    this.router.navigate(['./' + id], { relativeTo: this.route })
  }

  sortBy() {
    let filter: any = {};

    filter['private'] = { '$ne': true };

    if (this.searchSortBy) {
      filter.searchSortBy = this.searchSortBy
    }


    if (this.searchText) {
      filter.searchText = this.searchText
    }

    if (this.searchCategory) {
      filter.searchCategory = this.searchCategory
    }

    this.http.get('/news/search', filter)
      .subscribe((data: any) => {
        this.rows = data;
      });
  }

  showMore() {
    this.totalPerPage += 6;
    this.getNews();
  }
}
