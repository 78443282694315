import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { FacebookService } from 'src/app/shared/facebook.service';

import { Globals } from 'src/app/shared/globals';

@Component({
  selector: 'app-single-resource',
  templateUrl: './single.component.html',
  styles: []
})
export class SingleResourceComponent implements OnInit {
  api_route = 'resources';
  id: number;
  private sub: any;
  resource: {} = {};
  resources: [] = [];
  display = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: ApiService,
    private fb: FacebookService,
    private globals: Globals
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.route.queryParams.subscribe(queryParams => {
        this.id = params['id'];

        if (this.id) this.getResource(queryParams);
        
      });
    });
  }

  getResource(queryParams) {
    this.http.get('/' + this.api_route + '/' + this.id, 0).subscribe(data => {
      if (
        data['published'] ||
        (!data['published'] && queryParams['viewPreview'])
      ) {
        this.resource = data;
        this.fb.viewContent({
          content_name: this.id,
          content_category: 'resources'
        });
        // Set display rules
        if(this.globals['userType'] === "Internal" || this.resource['public'] || (this.globals['memberType'] == 'Rightsholder' && this.resource['rightsholderOnly'] && this.globals['loggedIn']) || (!this.resource['rightsholderOnly'] && this.globals['loggedIn']) ) {
          this.display = true;
        }
      } else {
        this.router.navigate(['/resources']);
      }
    }, error => {
      if (error)
        this.router.navigate(['./']);
    });
  }

  getRelatedResources() {
    this.http.get('/resources/front', { page: 1, limit: 4 }).subscribe(data => {
      this.resources = data['resources'];
    });
  }

  goTo(id) {
    this.router.navigate(['./resources/' + id]);
  }
}
