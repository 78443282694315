import { Injectable } from '@angular/core';
declare const fbq;

@Injectable({
  providedIn: 'root'
})
export class FacebookService {
  constructor() {}

  isFbqUndefined(): boolean {
    if (typeof fbq === 'undefined') {
      return true;
    }
    return false;
  }

  viewContent(properties: Object) {
    if (!this.isFbqUndefined()) {
      fbq('track', 'ViewContent', properties);
    }
  }

  lead() {
    if (!this.isFbqUndefined()) {
      fbq('track', 'Lead');
    }
  }

  contact() {
    if (!this.isFbqUndefined()) {
      fbq('track', 'Contact');
    }
  }

  pageView() {
    if (!this.isFbqUndefined()) {
      fbq('track', 'PageView');
    }
  }
}
