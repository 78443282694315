import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService } from 'src/app/shared/api.service';
import { Globals } from 'src/app/shared/globals';

@Component({
  selector: 'app-responses',
  templateUrl: './responses.component.html',
  styleUrls: ['./responses.component.css']
})
export class ResponsesComponent implements OnInit {
  company = {};
  users = [{}];
  responses = [];
  opportunity = {
    title: '',
  };
  id;
  constructor(private router: Router, private route: ActivatedRoute, private http: ApiService, private globals: Globals, public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {

    if (!this.globals.loggedIn || this.globals.memberType != 'Friend of AIM')
      this.router.navigate(['./settings/'])

    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.getResponses();
      this.getOpportunity();
    })
  }

  getResponses() {
    this.http.get('/responses/opportunity/' + this.id, 0)
      .subscribe((data: any) => {
        this.responses = data;
      });
  }

  getOpportunity() {
    this.http.get('/opportunities/' + this.id, 0)
      .subscribe((data: any) => {
        this.opportunity = data;
      });
  }

  goTo(responseId) {
    this.router.navigate(['./' + responseId], { relativeTo: this.route })
  }

  goBack() {
    this.router.navigate(['./settings/'])
  }

  thumbsUp(response) {
    response['userStatus'] = response['userStatus'] == "thumbs up" ? null : "thumbs up";
    this.http.put('/responses/' + response._id, response, {})
      .subscribe((data: any) => {
        console.log(data);
      });
  }

  thumbsDown(response) {
    response['userStatus'] = response['userStatus'] == "thumbs down" ? null : "thumbs down";
    this.http.put('/responses/' + response._id, response, {})
      .subscribe((data: any) => {
        console.log(data);
      });
  }
 
}
