import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
    api_url = "https://api.aim.org.uk";
    
    // api_url = "http://localhost:3000";

    spinnerLoading = false;
    //Stores all uploads progress to display it to the bars
    progressBars = {};
    loggedIn = false;
    memberType = "";
    userType = 'Standard';
    companyId;
    //stripeKey = 'pk_test_1XU6mtxiHE5TaJj8hEkvS2K0';
    stripeKey = 'pk_live_IEStrsaHj5pozRSK95fJQrcH';
    recaptchaToken;
}
